window.jQuery = window.$ = require('jquery');

// Make jQuery use passive listeners on touch and wheel event listeners
// https://web.dev/uses-passive-event-listeners/
// https://stackoverflow.com/a/62177358/3622332
jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener('touchstart', handle, { passive: !ns.includes('noPreventDefault') });
    }
};
jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        this.addEventListener('touchmove', handle, { passive: !ns.includes('noPreventDefault') });
    }
};
jQuery.event.special.wheel = {
    setup: function (_, ns, handle) {
        this.addEventListener('wheel', handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function (_, ns, handle) {
        this.addEventListener('mousewheel', handle, { passive: true });
    }
};

var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/input'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/newsletter'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/quantity'));
    processInclude(require('./components/tracker'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('./components/countryPopup'));
    processInclude(require('./components/gtm'));
    processInclude(require('./components/collect'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('dragscroll/dragscroll'));
});

require('./thirdParty/bootstrap');
require('./components/spinner');
require('./components/headerBanner');
