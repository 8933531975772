'use strict';

const { Splide } = require('@splidejs/splide/dist/js/splide.cjs.js');
const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
let headerCarousel;

// Build carousel from messages JSON
function buildHeaderCarousel(messages) {
    const headerCarouselWrapper = document.querySelector('.header-aside__carousel .splide__list');

    for (let message of messages) {
        const slide = document.createElement('li');
        slide.classList.add('splide__slide');

        if (message.isLink) {
            const link = document.createElement('a');
            link.href = message.url;
            link.classList.add('top-header-banner__link');
            link.insertAdjacentHTML('beforeend', message.message);
            slide.append(link);
        } else {
            const text = document.createElement('p');
            text.insertAdjacentHTML('beforeend', message.message);
            slide.append(text);
        }
        if (message.finePrintBody) {
            const finePrintLink = document.createElement('a');
            finePrintLink.setAttribute('tabindex', 0);
            finePrintLink.setAttribute('role', 'button');
            finePrintLink.setAttribute('data-toggle', 'modal');
            finePrintLink.setAttribute('data-target', '#details');
            finePrintLink.setAttribute('data-heading', message.finePrintHeading ? message.finePrintHeading : 'Details');
            finePrintLink.setAttribute('data-message', message.finePrintBody);
            finePrintLink.textContent = message.finePrintButtonText ? message.finePrintButtonText : 'Details';
            finePrintLink.classList.add('details-button');
            // finePrintLink.insertAdjacentHTML('afterbegin', '&nbsp');
            slide.insertAdjacentHTML('beforeend', '&nbsp');
            slide.append(finePrintLink);
        }

        headerCarouselWrapper.append(slide);
    }
}

function initHeaderCarousel() {
    const headerCarouselSettings = {
        // Optional parameters
        type: 'fade',
        rewind: true,
        autoplay: true,
        interval: 4000,
        pauseOnHover: true,
        pauseOnFocus: true,
        arrows: true,
        pagination: false
    };

    if (!mediaQuery || mediaQuery.matches) {
        headerCarouselSettings.autoplay = false;
    }
    headerCarousel = new Splide('.header-aside__carousel', headerCarouselSettings).mount();
}

// Setup watchers on detail modals to start and stop carousel
function detailsModalEventListenerInit() {
    const detailsModal = document.querySelector('.details-modal');
    const detailsButtons = document.querySelectorAll('.details-button');
    const modalTitleEl = detailsModal.querySelector('.details-modal .modal-title');
    const modalBodyEl = detailsModal.querySelector('.modal-body');

    // Update modal content with the last clicked details button
    detailsButtons.forEach(button => {
        button.addEventListener('click', function () {
            modalTitleEl.innerHTML = this.dataset.heading;
            modalBodyEl.innerHTML = this.dataset.message;
      });
    });

    // Pause and play slideshow  when popup is opened and closed
    $(detailsModal).on('show.bs.modal', function () {
        headerCarousel.Components.Autoplay.pause();
    });

    $(detailsModal).on('hidden.bs.modal', function () {
        if (mediaQuery && !mediaQuery.matches) {
            headerCarousel.Components.Autoplay.play();
        }
    });
}

var ready = (callback) => {
    if (document.readyState !== 'loading') {
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
};

ready(() => {
    const messageContainer = document.querySelector('.message-container');

    if (messageContainer && messageContainer.dataset.messages.length) {
        // Get and parse messages from data attrbiute on message-container
        const temp1 = JSON.parse(messageContainer.dataset.messages.replace(/&quot;/g, '\\"').replace(/(\r\n\t|\n|\r\t|\t)/gm, ''));

        const messages = [];
        /* eslint-disable guard-for-in, no-restricted-syntax */
        for (const message in temp1) {
            messages.push(temp1[message]);
        }

        if (messages) {
            buildHeaderCarousel(messages);
            initHeaderCarousel();
            detailsModalEventListenerInit();
        }
    } else if (messageContainer) {
        messageContainer.remove();
    }
});
