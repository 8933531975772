'use strict';

module.exports = {
    clear: function () {
        $('body').on('gtm:clear', function (e, resp) {
            if (window.dataLayer) {
                window.dataLayer.push({
                    ecommerce: null
                }); // Clear the previous ecommerce object.
            }
        });
    },
    productClick: function () {
        $('body').on('gtm:pdp:click', function (e, resp) {
            let actionURL = $('input[name="gtm"]').val().replace('XRouteX', 'ProductDetail');
            $.ajax({
                url: actionURL + '?productId=' + resp.productId,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('body').trigger('gtm:clear');
                    if (!data.error && window.dataLayer) {
                        window.dataLayer.push({
                            event: 'productClick',
                            ecommerce: {
                                click: {
                                    products: [{
                                        name: data.productName,
                                        id: data.productId,
                                        price: data.productPrice,
                                        brand: data.productBrand,
                                        category: data.productCategory,
                                        variant: data.productVariant
                                    }]
                                }
                            },
                            eventCallback: function () {
                                document.location = $(resp.trigger).attr('href')
                            }
                        });
                    } else {
                        document.location = $(resp.trigger).attr('href')
                    }
                },
                error: function (err) {
                    document.location = $(resp.trigger).attr('href')
                }
            });
        });
    },
    productView: function () {
        $('body').on('gtm:pdp:view', function (e, resp) {
            let actionURL = $('input[name="gtm"]').val().replace('XRouteX', 'ProductDetail');
            $.ajax({
                url: actionURL + '?productId=' + resp.productId,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('body').trigger('gtm:clear');
                    if (!data.error && window.dataLayer) {
                        window.dataLayer.push({
                            event: 'productView',
                            ecommerce: {
                                detail: {
                                    products: [{
                                        name: data.productName,
                                        id: data.productId,
                                        price: data.productPrice,
                                        brand: data.productBrand,
                                        category: data.productCategory,
                                        variant: data.productVariant
                                    }]
                                }
                            }
                        });
                        window.dataLayer.push({ 'product-name': data.productName });
                        if (data.style) {
                            window.dataLayer.push({ style: data.style });
                        }
                    }
                },
                error: function (err) {}
            });
        });
    },
    addToCart: function () {
        $('body').on('gtm:pdp:add', function (e, resp) {
            let actionURL = $('input[name="gtm"]').val().replace('XRouteX', 'ProductDetail');
            $.ajax({
                url: actionURL + '?productId=' + resp.form.pid,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('body').trigger('gtm:clear');
                    if (!data.error && window.dataLayer) {
                        window.dataLayer.push({
                            event: 'addToCart',
                            ecommerce: {
                                add: { // 'remove' actionFieldObject measures.
                                    products: [{ //  removing a product to a shopping cart.
                                        name: data.productName,
                                        id: data.productId,
                                        price: data.productPrice,
                                        brand: data.productBrand,
                                        category: data.productCategory,
                                        variant: data.productVariant,
                                        quantity: resp.form.quantity
                                    }]
                                }
                            }
                        });
                    }
                },
                error: function (err) {}
            });
        });
    },
    removeFromCart: function () {
        $('body').on('gtm:pdp:remove', function (e, resp) {
            let actionURL = $('input[name="gtm"]').val().replace('XRouteX', 'ProductDetail');
            $.ajax({
                url: actionURL + '?productId=' + resp.productId,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('body').trigger('gtm:clear');
                    if (!data.error && window.dataLayer) {
                        window.dataLayer.push({
                            event: 'removeFromCart',
                            ecommerce: {
                                remove: { // 'remove' actionFieldObject measures.
                                    products: [{ //  removing a product to a shopping cart.
                                        name: data.productName,
                                        id: data.productId,
                                        price: data.productPrice,
                                        brand: data.productBrand,
                                        category: data.productCategory,
                                        variant: data.productVariant
                                    }]
                                }
                            }
                        });

                    }
                },
                error: function (err) {}
            });
        });
    },
    checkout: function () {
        $('body').on('gtm:order:checkout', function (e, resp) {
            let actionURL = $('input[name="gtm"]').val().replace('XRouteX', 'Cart');
            $.ajax({
                url: actionURL,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('body').trigger('gtm:clear');
                    if (!data.error && window.dataLayer) {
                        window.dataLayer.push({
                            event: 'checkout',
                            ecommerce: {
                                checkout: {
                                    actionField: {
                                        step: resp.currentStage
                                    },
                                    products: data.cart
                                }
                            }
                        });
                    }
                },
                error: function (err) {}
            });
        });
    },
    purchase: function () {
        $('body').on('gtm:order:purchase', function (e, resp) {
            let actionURL = $('input[name="gtm"]').val().replace('XRouteX', 'Purchase');
            $.ajax({
                url: actionURL + '?orderNo=' + resp.orderNo,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $('body').trigger('gtm:clear');
                    if (!data.error && window.dataLayer) {
                        window.dataLayer.push({
                            event: 'transaction',
                            ecommerce: {
                                purchase: {
                                    actionField: {
                                        id: data.orderNo, // Transaction ID. Required for purchases and refunds.
                                        revenue: data.revenue, // Total transaction value (incl. tax and shipping)
                                        tax: data.tax,
                                        shipping: data.shipping,
                                        coupon: data.coupon
                                    },
                                    products: data.productList
                                }
                            },
                            customerEmailSHA256: data.customerEmailSHA256,
                            customerPhoneSHA256: data.customerPhoneSHA256
                        });
                    }
                },
                error: function (err) {
                }
            });
        });
    },
    productClicks: function () {
        $('body').on('click', '.pdp-click--gtm__js', function (e) {
            e.preventDefault();
            if (window.dataLayer && window.ga && window.ga.hasOwnProperty('loaded') && window.ga.loaded === true) {
                let productId = null;
                if ($(this).closest('.product').length > 0) {
                    productId = $(this).closest('.product').data('pid');
                } else if ($(this).closest('.product-tile-pd').length > 0) {
                    productId = $(this).closest('.product-tile-pd').data('pid');
                } else {
                    productId = $(this).closest('.product-info').data('pid');
                }
                $('body').trigger('gtm:pdp:click', {
                    productId: productId,
                    trigger: $(this)
                });
            } else {
                document.location = $(this).attr('href');
            }
        });
    }
};