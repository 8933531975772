'use strict';

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

/**
 * Check if cookie exists by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {integer} -1 if it does not exist, otherwise index
 */
function cookieExists(cookieName) {
    return document.cookie.indexOf(`${cookieName}=`) > -1;
}

let trackingCookie = parseInt(getCookie('dw_dnt'), 10);

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @param {string} cookieValue - value of the cookie
 * @param {string} exdays - expiration of the cookie
 */
function setCookie(cookieName, cookieValue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
}

/**
 * Resize email sign up popup
 */
function centerEmailSignUpPopup() {
    var $popup = $('#emailSignupPopup');
    var $window = $(window);
    var top = (($window.height() - ($popup.height() / 2))) + $window.scrollTop();
    $popup.css('top', top + 'px');
}

/**
 * Close email sign up popup
 */
function closeEmailSignUp() {
    trackingCookie = parseInt(getCookie('dw_dnt'), 10);
    if (trackingCookie === 0) {
        setCookie('emailSignUpClosed', '1', 30);
    }
    $('#emailSignupPopup, .overlay').fadeOut('fast');
    $(window).off('resize', centerEmailSignUpPopup);
}


/**
 * Open email sign up popup
 */
function openEmailSignUpPopup() {
    if (!$('.overlay').length) {
        $('body').append('<div class="overlay"></div>');
    }
    $('#emailSignupPopup, .overlay').fadeIn('slow');
    centerEmailSignUpPopup();
    $(window).on('resize', centerEmailSignUpPopup);
    $(window).on('keydown', function (event) {
        if (event.key === 'Escape') {
            closeEmailSignUp();
        }
    });
    $('body').on('click', '.overlay', function (event) {
        if (!$(event.target).is('#emailSignupPopup')) {
            closeEmailSignUp();
        }
    });
}

if ($('.storepage').length === 0 || ($('.storepage').length > 0 && $('.storepage').data('view-mode') === true)) {
    // Don't show popup if email popup has already been closed, if the country switcher popup is displayed, if the cookie consent banner hasn't been closed, or if consent has been denied.
    if (getCookie('emailSignUpClosed') !== '1' && cookieExists('OptanonAlertBoxClosed') && !cookieExists('upsellitopened') && $('#emailSignupPopup').length && trackingCookie === 0 && (!document.getElementById('countrySwitcherOverlay') || document.getElementById('countrySwitcherOverlay').dataset.countryPref !== 'null') && !document.getElementsByClassName('js-no-subscribe-popup').length) {
        $.get($('#emailSignupPopup').data('action'), function (data) {
            if (data.showModal) {
                setTimeout(openEmailSignUpPopup(), 10000);
            }
        });
    }
}

$(window).on('message', function (e) {
    var data = e.originalEvent.data;
    if (data === 'closeModal') {
        closeEmailSignUp();
    }
});

module.exports = {
    closeEmailSignUp: function () {
        $('body').on('click', '.emailSignupNoThanks', function (e) {
            e.preventDefault();

            closeEmailSignUp();
        });
    },
    centerEmailSignUpPopup: centerEmailSignUpPopup,
    openEmailSignUpPopup: function () {
        $('body').on('click', '.c-newsletter__button', function (e) {
            e.preventDefault();

            openEmailSignUpPopup();
        });
    }
};
