'use strict';

$('.form-control').each(function (index) {
    /* if (!$(this).val()) {
    	$(this).parent().find('.form-control-label:not(.form-control-label-prefilled)').addClass('d-none');
    } else {
    	$(this).parent().find('.form-control-label:not(.form-control-label-prefilled)').removeClass('d-none');
    } */
});
module.exports = {
    showInputLabels: function () {
        $(document).on('focus', '.form-control', function () {
            /*  $(this).parent().find('.form-control-label:not(.form-control-label-prefilled)').removeClass('d-none'); */
        });
    },
    hideInputLabel: function () {
        $(document).on('focusout', '.form-control', function () {
            /* if (!$(this).val()) {
                $(this).parent().find('.form-control-label:not(.form-control-label-prefilled)').addClass('d-none');
            } */
        });
    },
    phoneInput: function () {
        $(document).on('keyup', 'input[type="tel"]', function () {
            this.value = this.value
                .match(/\d*/g).join('')
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/).slice(1).join('-')
                .replace(/-*$/g, '');
        });
    },
    validateInputLabels: function () {
        $('body').on('input:validateInputLabels', function (e) {
            e.preventDefault();
            /* $( '.form-control' ).each(function( index ) {
                if (!$(this).val()) {
                    $(this).parent().find('.form-control-label:not(.form-control-label-prefilled)').addClass('d-none');
                } else {
                    $(this).parent().find('.form-control-label:not(.form-control-label-prefilled)').removeClass('d-none');
                }
            }); */
        })
    }
};