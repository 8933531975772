'use strict';

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

function parseCookie(cookie) {
    return cookie.split('&')
        .map(v => v.split('='))
        .reduce((acc, v) => {
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
            return acc;
        }, {});
}

function consent(url) {
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function () {
            $('#consent-tracking').remove();
            $.spinner().stop();
        },
        error: function () {
            $('#consent-tracking').remove();
            $.spinner().stop();
        }
    });
}

module.exports = function () {
    if (getCookie('OptanonConsent') !== '') {
        const oneTrust = parseCookie(getCookie('OptanonConsent'));
        const trackingConsentElement = document.querySelector('.tracking-consent');
        // Check if c0004 (Targeting Cookies category from OneTrust) is approved or not and trigger the corresponding accept or reject consent.
        let url = oneTrust.groups.toLowerCase().includes('c0004:1') ? trackingConsentElement.dataset.accept : trackingConsentElement.dataset.reject;

        consent(url);
    }
};
