'use strict';

const countrySwitcher = document.getElementById('countrySwitcherOverlay');

const availableCountries = [
    'CA', 'US'
];

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

/**
 * Set cookie name, value, and expiration
 * @param {string} cookieName - name of the cookie
 * @param {string} cookieValue - value of the cookie
 * @param {string} exdays - expiration of the cookie
 */
function setCookie(cookieName, cookieValue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/';
}

/**
 * Redirect to the destination website with all products in the basket if exist
 * @param {string} country - Element that has Country, setPrefCountry, redirect Information
 */
function redirectToWebsite(country) {
    $.ajax({
        url: $(country).data('redirect'),
        contentType: 'application/json',
        success: function (data) {
            if (data.redirectUrl && data.redirectUrl != undefined) {
                location.href = data.redirectUrl;
            } else {
                $.spinner().stop();
            }
        },
        error: function (e) {
            $.spinner().stop();
        }
    });
}

/**
 * Set cookie prefcountrywgw into the destination website
 * @param {string} country - Element that has Country, setPrefCountry, redirect Information
 * @param {string} response - JSON with redirect key (Boolean) and setPrefCountry endpoint
 */
function setPrefCountryAndRedirect(country, response) {
    if (availableCountries.includes($(country).data('country').toUpperCase())) {
        setCookie('prefcountrywgw', $(country).data('country').toUpperCase(), 1825);
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'countrySelected'
            });
        }
        if (response.redirect) {
            redirectToWebsite(country);
        }
    }
}

/**
 * Show country selector popup
 */
function showPopup() {
    if (countrySwitcher) {
        $('#countrySwitcherOverlay').modal();
        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'countrySwitcherOpened'
            });
        }
    }
}

/**
 * Close country selector popup event listener. Once called it will create/update a cookie on the other SJ website
 */
$('#countrySwitcherOverlay').on('hidden.bs.modal', function () {
    setPrefCountryAndRedirect(countrySwitcher, {
        redirect: false
    });
});

/**
 * Choose country listener. Once country selected it will create/update a cookie on the destination SJ website and redirect
 */
$('body').on('click', '#countrySwitcherOverlay .country, .dropdown-country-selector .dropdown-item, .country-selector .country-anchor', function (e) {
    e.preventDefault();
    $.spinner().start();
    let siteCountry = countrySwitcher ? countrySwitcher.dataset.country : $('.page__country-switcher--js').data('country');
    var country = $(this);
    var isCountryHeaderSelector = $(country).hasClass('dropdown-item');
    if (siteCountry !== 'null' && $(country).data('country') && siteCountry.toUpperCase() !== $(country).data('country').toUpperCase()) {
        setPrefCountryAndRedirect(country, {
            redirect: true
        });
    } else if (!isCountryHeaderSelector) {
        $('#countrySwitcherOverlay').modal('hide');
        $.spinner().stop();
    } else {
        $.spinner().stop();
    }
});

var country = countrySwitcher ? countrySwitcher.dataset.country : $('.page__country-switcher--js').data('country');
var countryElement = countrySwitcher ? countrySwitcher : $('.page__country-switcher--js');

if ($('.storepage').length == 0 || ($('.storepage').length > 0 && $('.storepage').data('view-mode') == true)) {
    if (getCookie('prefcountrywgw').length === 0) {
        showPopup();
    }
}
