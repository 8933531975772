'use strict';
if ('undefined' === typeof(_etmc)) {
    window._etmc = window._etmc || [];
    if (window.setOrgId) {
        window._etmc.push(['setOrgId', window.setOrgId ]);
    }
    if (window.b2ccrm_contactId) {
        window._etmc.push(['setUserInfo', {'email': window.b2ccrm_contactId } ]);
    }
}
module.exports = {
    search: function () {
        $('body').on('collect:search', function (e, searchKeyWord) {
            if (searchKeyWord.trim() !== '' && window._etmc) {
                window._etmc.push(['trackPageView', { 'search': searchKeyWord.trim() }]);
            }
        });
    },
    categoryView: function () {
        $('body').on('collect:category:view', function (e, data) {
            if (data && data.categoryId && window._etmc) {
                window._etmc.push(['trackPageView', { 'category': data.categoryId }]);
            }
        });
    },
    productView: function () {
        $('body').on('collect:pdp:view', function (e, data) {
            if (data && data.productId && window._etmc) {
                window._etmc.push(['trackPageView', { 'item': data.productId, 'search': data.productId }]);
            }
        });
    },
    addToCart: function () {
        $('body').on('collect:cart:addToCart', function (e, data) {
            var items = [];
            if (data && data.cart && window._etmc) {
                data.cart.items.forEach(function (item) {
                    items.push({
                        item: item.id,
                        quantity: item.quantity,
                        price: item.price.sales.decimalPrice,
                        unique_id: item.uuid
                    });
                });
                window._etmc.push(['trackCart', { 'cart': items }]);
            }
        });
    },
    cartView: function () {
        $('body').on('collect:cart:view', function (e, data) {
            var items = [];
            if (data && window._etmc) {
                if (typeof data === 'string') {
                    data = JSON.parse(data);
                }
                data.forEach(function (item) {
                    items.push({
                        item: item.id,
                        quantity: item.quantity,
                        price: item.price.sales.decimalPrice,
                        unique_id: item.uuid
                    });
                });
                window._etmc.push(['trackCart', { 'cart': items }]);
            }
        });
    },
    cartUpdate: function () {
        $('body').on('collect:cart:update', function (e, data) {
            var items = [];
            if (data && data.items && window._etmc) {
                data.items.forEach(function (item) {
                    items.push({
                        item: item.id,
                        quantity: item.quantity,
                        price: item.price.sales.decimalPrice,
                        unique_id: item.uuid
                    });
                });
                window._etmc.push(['trackCart', { 'cart': items }]);
            }
        });
    },
    cartEmpty: function () {
        $('body').on('collect:cart:empty', function (e, empty) {
            if (window._etmc) {
                window._etmc.push(['trackCart', { 'clear_cart': empty }]);
            }
        });
    },
    orderConfirm: function () {
        $('body').on('collect:order:confirm', function (e, data) {
            var items = [];
            if (data && data.order && data.order.items.items && window._etmc) {
                data.order.items.items.forEach(function (item) {
                    items.push({
                        item: item.id,
                        quantity: item.quantity,
                        price: item.price.sales.decimalPrice,
                        unique_id: item.uuid
                    });
                });
                window._etmc.push(['trackConversion', {
                    'cart': items,
                    'order_number': data.order.orderNumber,
                    'discount': data.order.totals.orderLevelDiscountTotal.value,
                    'shipping': data.order.totals.totalShippingCost
                }]);
            }
        });
    }
};
