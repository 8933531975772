'use strict';
module.exports = function () {
    $('body').on('click', '.stepper__button-minus', function (e) {
        e.preventDefault();

        let value = $(this).parent().find('.stepper__input').val();

        if (value != '1') {
            $(this).parent().find('.stepper__input').val(parseInt(value, 10) - 1);
        }
    });

    $('body').on('click', '.stepper__button-plus', function (e) {
        e.preventDefault();

        let value = $(this).parent().find('.stepper__input').val();

        if ($(this).parent().find('.stepper__input').attr('max')) {
            if ($(this).parent().find('.stepper__input').attr('max') > value) {
                $(this).parent().find('.stepper__input').val(parseInt(value, 10) + 1);
            }
        } else {
            $(this).parent().find('.stepper__input').val(parseInt(value, 10) + 1);
        }
    });
}